/* eslint-disable global-require */
import React, { useContext } from 'react';
import { Helmet } from 'react-helmet';

import { AnalyticsLocationProvider } from 'latitude-analytics';
import { Link as LinkScroll } from 'react-scroll';
import RatesAndFees from '@latitude/rates-and-fees';
import { Link } from '@latitude/link';
import Section from '@latitude/section';
import { Accordion } from '@latitude/accordion';
import {
  ALIGN,
  BREAKPOINT,
  COLOR,
  MARGIN,
  BUTTON_STYLE,
  FONT_FAMILY
} from '@latitude/core/utils/constants';

import PaymentOptions, {
  PaymentOptionsColumn,
  PaymentOptionIcon,
  PaymentOptionTitle,
  PaymentOptionDescription,
  PaymentOptionImages,
  PaymentOptionButtons,
  PaymentOptionNote,
  PaymentOptionCallout,
  PaymentOptionList,
  PaymentOptionListItem,
  PaymentOptionHeading
} from '../../../components/PaymentOptions/PaymentOptions';

import Layout from '@/components/layout';
import { StickyNavigation } from '@latitude/sticky-navigation';

import { Heading3, Heading4 } from '@latitude/heading';
import { Box } from '@latitude/box';
import SvgInline from '@latitude/svg-inline';
import HowToApply from '@latitude/how-to-apply';
import { ListNumbered } from '@latitude/list-numbered';
import { List, ListItem } from '@latitude/list';
import { FeaturesSlider } from '@latitude/features-slider';

import Text from '@/components/Text/Text';
import { Strong } from '@/components/Text/Bold';

import { PageContext } from '@/context/PageContext';
import HeroBanner from '@/components/lab-components/HeroBanner';
import LabRatesAndFees from '@/components/lab-components/LabRatesAndFees';

import footerData from './data/footer.json';
import PageData from '../../../data/pages/credit-cards/twenty-eight-degree.json';
import ArticleTile from '../../../templates/life-done-better/ArticleTile';

import { ImportantInformation } from '@latitude/important-information';
import CarouselHeader from '@/components/Carousel/carouselHeader';
import { CardGroup, CardGroupItem } from '@/components/CardGroup/CardGroup';
import bookingBanner from './images/28d-travel-quarter-booking.webp';
import wotifBanner from './images/28d-travel-quarter-expedia-wotif.webp';
import quaterBanner from './images/28d-travel-quarter.webp';
import DegreesCardMicrositeHeader from './_28d-microsite-header';

import LatestOffersSection from './_latest-offers';

const HEADER_ITEMS = [
  {
    title: 'Latitude 28&deg; Global Platinum Mastercard<sup>&reg;</sup>',
    text:
      'Join the Great Escape and save on accommodation at Booking.com, Expedia and Wotif.',
    termsconds: '',
    headerImage: quaterBanner,
    urlFindOutMore: '',
    urlApplyNow: 'https://cards.latitudefinancial.com/28degrees'
  },
  {
    title:
      'Get an instant 6% off selected accom&shy;mo&shy;da&shy;tion at Booking.com<sup>4</sup>',
    text:
      'Available all day, every day, year-round with your Latitude 28° Global Platinum Mastercard® credit card via the Latitude App.',
    termsconds: '',
    headerImage: bookingBanner,
    urlFindOutMore: '',
    urlApplyNow: 'https://cards.latitudefinancial.com/28degrees'
  },
  {
    title: 'Enjoy 10% off selected hotels at Expedia and Wotif<sup>3</sup>.',
    text:
      'When you book with your Latitude 28° Global Platinum Mastercard® credit card before 30 September 2024 and stay before 31 December 2024.',
    termsconds: '',
    headerImage: wotifBanner,
    urlFindOutMore: '',
    urlApplyNow: 'https://cards.latitudefinancial.com/28degrees'
  },
  {
    title: 'Add another star to your accommodation!',
    text:
      'Enjoy great savings on selected accommodation with our travel partners.',
    termsconds: '',
    headerImage: wotifBanner,
    urlFindOutMore: '',
    urlApplyNow: 'https://cards.latitudefinancial.com/28degrees'
  }
];

const CARD_INFORMATION = [
  {
    title: 'Latitude Gem Visa',
    href: '#',
    trackId: 'interest-free--compare-cards--fom--creditline',
    imageSrc: require('../../../images/credit-cards/latitude-gem.png'),
    isVertical: true,
    productid: ['CCAUCCL']
  },
  {
    title: 'Latitude GO Mastercard',
    href: '#',
    trackId: 'interest-free--compare-cards--fom--buyers-edge',
    imageSrc: require('../../../images/credit-cards/go-mastercard.png'),
    isVertical: true,
    productid: ['CCAUBE']
  },
  {
    title: 'CreditLine',
    href: 'https://cardapp.latitudefinancial.com/apply/creditline/',
    trackId: 'interest-free--compare-cards--fom--creditline-afs',
    imageSrc: require('../../../images/credit-cards/creditline-afs.png'),
    isVertical: false,
    productid: ['CCAUAFS']
  }
];
const cardsHeading = 'Check out the other Latitude credit cards';

const KEEP_EXPLORING_INFO = [
  {
    title: 'Find your inspiration',
    thumbnail: 'find-your-inspiration.webp',
    path:
      'https://www.latitudefinancial.com.au/credit-cards/28-degrees/travel-inspiration/'
  },
  {
    title: 'Plan your trip',
    thumbnail: 'plan-your-trip.webp',
    path:
      'https://www.latitudefinancial.com.au/credit-cards/28-degrees/travel-inspiration/'
  },
  {
    title: 'Calculate your savings',
    thumbnail: 'calculate-your-savings.webp',
    path:
      'https://www.latitudefinancial.com.au/credit-cards/28-degrees/travel-inspiration/'
  }
];

const DegreesCardPage = props => {
  const featureSliderItems = [
    {
      icon: 'icon-tap',
      title: 'No fees!',
      text:
        'Pay no international transaction or currency conversion fees on purchases online or overseas.'
    },
    {
      icon: 'icon-tap',
      title: 'Ready, Set, Tap.',
      text:
        'Our new instant cardless payment feature lets you apply, get approval, and start shopping from your phone within minutes.'
    },
    {
      icon: 'icon-global-wifi',
      title: 'Free Flight Delay Pass & Global Data Roaming.',
      jsx: (
        <p
          css={`
            font-family: Roboto, Open Sans, sans-serif;
            font-size: 16px;
            font-weight: 300;
            line-height: 26px;
            text-align: center;
            color: #42484d;
          `}
        >
          Enjoy our complimentary{' '}
          <a
            href="/credit-cards/28-degrees/flight-delay-pass/"
            to="flight-delay-pass"
            data-trackid="28d--flight-delay"
          >
            Flight Delay Pass
          </a>
          <sup>
            <LinkScroll
              to="note-1"
              spy
              smooth
              duration={500}
              offset={-140}
              className="link-noline"
              href="#note-1"
            >
              1
            </LinkScroll>
          </sup>
          {' and '}
          <a
            href="/credit-cards/28-degrees/global-data-roaming/"
            to="global-data-roaming"
            data-trackid="28d--global-wifi"
          >
            Global Data Roaming
          </a>
          <sup>
            <LinkScroll
              to="note-2"
              spy
              smooth
              duration={500}
              offset={-140}
              className="link-noline"
              href="#note-2"
            >
              2
            </LinkScroll>
          </sup>
          .
        </p>
      )
    },
    {
      icon: 'icon-pl-2',
      title: 'Booking.com partnership',
      jsx: (
        <p
          css={`
            font-family: Roboto, Open Sans, sans-serif;
            font-size: 16px;
            font-weight: 300;
            line-height: 26px;
            text-align: center;
            color: #42484d;
          `}
        >
          Get 6% off your stay instantly with{' '}
          <a href="/travelpartners">Booking.com</a> every day, all year round
          via the Latitude App
          <sup>
            <LinkScroll
              to="note-4"
              spy
              smooth
              duration={500}
              offset={-170}
              href="#note-1"
              className="link-noline"
            >
              4
            </LinkScroll>
          </sup>
          .
        </p>
      )
    }
  ];
  const gatsbyTealiumEnv = process.env.GATSBY_TEALIUM_ENV || 'unknown';
  const dynamicYieldKey = gatsbyTealiumEnv === 'prod' ? '8790471' : '8790470';

  /** Contentful Data */
  const contentfulPageData = useContext(PageContext);
  const state = contentfulPageData?.updatedBody || {};
  const featureSliderData = [
    {
      heading: 'Why choose a Latitude 28° Global?',
      featureCards: featureSliderItems
    }
  ];
  /** - END - */

  return (
    <Layout customFooter={footerData} location={props.location} hasSecNav>
      <main
        className="navigation-spacer"
        css={`
          margin-top: 10px !important;
          background-color: #f8f8f8;
          .microsite-header {
            z-index: 2000 !important;
          }

          div[class^='MicrositeHeader__SecondBar'].microsite-header__nav-wrap {
            margin-top: 8px;
          }

          [class^='HowToApply__StyledConfetti'],
          [class^='RatesListBranded__RatesList-']::before,
          [class^='RatesListBranded__RatesList-']::after {
            display: none;
          }
        `}
      >
        <Helmet defer={false}>
          <link
            rel="canonical"
            href="https://www.latitudefinancial.com.au/credit-cards/28-degrees/"
          />
          <title>28 Degrees Mastercard | Latitude Financial</title>
          <meta
            name="description"
            content="28 Degrees Mastercard Travel Card"
          />

          <link rel="preconnect" href="//cdn.dynamicyield.com" />
          <link rel="preconnect" href="//st.dynamicyield.com" />
          <link rel="preconnect" href="//rcom.dynamicyield.com" />
          <link rel="dns-prefetch" href="//cdn.dynamicyield.com" />
          <link rel="dns-prefetch" href="//st.dynamicyield.com" />
          <link rel="dns-prefetch" href="//rcom.dynamicyield.com" />
          <script type="text/javascript">
            {`
    window.DY = window.DY || {};
    DY.recommendationContext = { type: "PRODUCT", data: ['28-degrees'] };
  `}
          </script>
          <script
            type="text/javascript"
            src={`//cdn.dynamicyield.com/api/${dynamicYieldKey}/api_dynamic.js`}
          />
          <script
            type="text/javascript"
            src={`//cdn.dynamicyield.com/api/${dynamicYieldKey}/api_static.js`}
          />
        </Helmet>
        <DegreesCardMicrositeHeader data={state.secondaryNavData} />
        {state?.heroBannerData?.[0] ? (
          <HeroBanner {...state?.heroBannerData[0]} headingSize="Heading 2" />
        ) : (
          <CarouselHeader HEADER_ITEMS={HEADER_ITEMS} />
        )}

        <div
          className="d-none d-lg-block"
          css={`
            position: relative;
            z-index: 10;
            .sticky-navigation--fixed {
              top: 40px !important;
            }
          `}
        >
          <StickyNavigation
            items={[
              {
                anchor: 'why-us',
                label: 'Why us?'
              },
              {
                anchor: 'latest-offers',
                label: 'Offers'
              },
              {
                anchor: 'rates',
                label: 'Rates & Fees'
              },
              {
                anchor: 'how-to-apply',
                label: 'How to apply'
              },
              {
                anchor: 'payment-options',
                label: 'Payment Options'
              },
              {
                anchor: 'faq',
                label: 'FAQ'
              }
            ]}
            ctaText="Get Rate Estimate"
            offsetElem="[data-sticky-navigation-offset]"
            trackEventData={{
              category: 'cta',
              action: 'quote-link',
              location: 'sticky'
            }}
            trackId="sticky-get-started"
            {...state?.inPageNavData?.[0]}
          />
        </div>

        <FeaturesSlider
          key={(state?.featureSliderData?.[0] || featureSliderData[0]).heading}
          heading={
            (state?.featureSliderData?.[0] || featureSliderData[0]).heading
          }
          id="why-us"
          className="why-choose bg-f8f8f8"
          data={
            (state?.featureSliderData?.[0] || featureSliderData[0]).featureCards
          }
          subheading={
            (state?.featureSliderData?.[0] || featureSliderData[0]).description
          }
          css={`
            && {
              padding-bottom: 0;
            }
          `}
        />
        {state?.featureSliderData?.[1] && (
          <FeaturesSlider
            key={
              (state?.featureSliderData?.[1] || featureSliderData[1]).heading
            }
            heading={
              (state?.featureSliderData?.[1] || featureSliderData[1]).heading
            }
            id="why-us-2"
            className="why-choose bg-f8f8f8"
            data={
              (state?.featureSliderData?.[1] || featureSliderData[1])
                .featureCards
            }
            subheading={
              (state?.featureSliderData?.[1] || featureSliderData[1])
                .description
            }
            css={`
              && {
                padding-top: 0;
              }
            `}
          />
        )}

        <LatestOffersSection anchor="latest-offers" />

        {state?.ratesAndFeesData?.[0] ? (
          <LabRatesAndFees {...state.ratesAndFeesData[0]} />
        ) : (
          <AnalyticsLocationProvider location="Rates and fees">
            <RatesAndFees
              css={`
                .accordion__item > button {
                  font-weight: 500 !important;
                }
                .accordion-custom .accordion__row {
                  padding: 5px 0;
                  margin: 0 30px;
                }
                .accordion-custom p {
                  margin-top: 16px;
                  font-weight: 300;
                  strong {
                    font-weight: 500;
                    color: ${COLOR.BLACK};
                  }
                }
              `}
              id="rates"
              title="Rates, Fees and Conditions"
              ratesListProps={{
                data: [
                  {
                    largeText: '$0',
                    smallText: 'international transaction fees on purchases'
                  },
                  {
                    largeText: '$0',
                    smallText: 'currency conversion fees on purchases'
                  },
                  {
                    largeText: '$0',
                    smallText: 'additional cardholder fee'
                  },
                  {
                    largeText: '0',
                    showExtras: true,
                    smallText: (
                      <React.Fragment>
                        up to 55 days interest free on purchases
                        <sup>
                          <LinkScroll
                            to="note-hash"
                            spy
                            smooth
                            duration={500}
                            offset={-140}
                            className="link-noline"
                            href="#note-hash"
                          >
                            #
                          </LinkScroll>
                        </sup>
                      </React.Fragment>
                    )
                  },
                  {
                    largeText: '27.99',
                    showExtras: true,
                    smallText: 'interest rate on purchases'
                  },
                  {
                    largeText: '$8',
                    smallText: <>card fee per month.</>
                  }
                ]
              }}
              accordionProps={{
                items: [
                  {
                    id: 'other-fees',
                    title: 'Other Rates, Fees and Conditions',
                    content: (
                      <div className="accordion-custom">
                        <div className="d-lg-flex accordion__row">
                          <p className="w-100">
                            <strong>Min credit limit</strong>
                          </p>
                          <p className="w-100">$1000</p>
                        </div>
                        <div className="d-lg-flex accordion__row">
                          <p className="w-100">
                            <strong>Interest rate on cash advances</strong>
                          </p>
                          <p className="w-100">29.99% p.a.</p>
                        </div>
                        <div className="d-lg-flex accordion__row">
                          <p className="w-100">
                            <strong>Interest rate on balance transfers</strong>
                          </p>
                          <p className="w-100">
                            Please refer to the Balance Transfer interest rate,
                            term and balance transfer fee set out in any offer
                            presented to you.
                          </p>
                        </div>
                        <div className="d-lg-flex accordion__row">
                          <p className="w-100">
                            <strong>Cash advance fee</strong>
                          </p>
                          <p className="w-100">
                            3.5% of the cash advance or $4 (whichever is
                            greater).
                          </p>
                        </div>

                        <div className="d-lg-flex accordion__row">
                          <p className="w-100">
                            <strong>Payment handling fee</strong>
                          </p>
                          <p className="w-100">
                            $3.95 (in person), $1.95 (online).
                            <br />
                            You can avoid this fee by making your payments via
                            mail, direct debit or via the Latitude Service
                            Centre.
                          </p>
                        </div>

                        <div className="d-lg-flex accordion__row">
                          <p className="w-100">
                            <strong>Paper statements</strong>
                          </p>
                          <p className="w-100">
                            $5.00
                            <br />
                            You can avoid this fee by making your payments via
                            mail, direct debit or via the Latitude Service
                            Centre.
                          </p>
                        </div>

                        <div className="d-lg-flex accordion__row">
                          <p className="w-100">
                            <strong>Late fee</strong>
                          </p>
                          <p className="w-100">$45</p>
                        </div>
                        <div className="d-lg-flex accordion__row">
                          <p className="w-100">
                            <strong>Balance Transfer fee</strong>
                          </p>
                          <p className="w-100">
                            Up to 3% of balance transfer amount
                          </p>
                        </div>
                      </div>
                    )
                  },
                  {
                    id: 'other-information',
                    title: 'Other information',
                    content: (
                      <div className="accordion-custom">
                        <div className="d-lg-flex accordion__row">
                          <p className="w-100">
                            <strong>
                              Before making a decision, please read these
                              documents to ensure you&apos;re aware of all fees,
                              charges and interest rates.
                            </strong>
                          </p>
                          <p className="w-100">
                            <a href="https://assets.latitudefinancial.com/legals/key-fact-sheet/28-degrees/kfs.html">
                              Key facts sheet
                            </a>
                            <br />
                            <a href="https://assets.latitudefinancial.com/legals/conditions-of-use/28degrees-au/cou.pdf">
                              Conditions of use
                            </a>
                          </p>
                        </div>
                      </div>
                    )
                  }
                ]
              }}
            />
          </AnalyticsLocationProvider>
        )}

        <HowToApply
          id="how-to-apply"
          box1={
            <div
              css={`
                .list-numbered li {
                  align-items: flex-start;
                  color: ${COLOR.BLACK};
                }
              `}
            >
              <Heading4
                marginBottom={MARGIN.M16}
                isResponsive={false}
                color={COLOR.BLACK}
              >
                How to apply
              </Heading4>
              <ListNumbered
                data={[
                  `<span>
                      <strong style="font-weight:500;">Apply online</strong> <br /> You can check your
                      eligibility and apply in 10 minutes.</span>
                    </span>`,
                  `<span>
                      <strong style="font-weight:500;">Wait a minute</strong> <br /> 60 seconds is all it takes for us to respond to your application.</span>
                    </span>`,
                  `<span>
                      <strong style="font-weight:500;">Ready, set, shop</strong> <br /> Simply activate your card when it arrives to start shopping and reaping the rewards.</span>
                    </span>`
                ]}
                viewStyle="compact"
                separator="none"
                counterBgColor={COLOR.BLUE_DEEP}
              />
              <Link
                button={BUTTON_STYLE.TERTIARY}
                css="width: 100%; margin: 32px 0 auto;"
                href="https://cards.latitudefinancial.com/28degrees"
                data-trackid="apply-now"
                trackEventData={{
                  label: 'Apply now'
                }}
              >
                Apply now
              </Link>
            </div>
          }
          box2={
            <div
              css={`
                && {
                  p {
                    color: ${COLOR.BLACK};
                  }
                  ul {
                    color: ${COLOR.BLACK};
                    margin-left: 24px;
                    li:before {
                      line-height: 0.85em;
                    }
                  }
                }
              `}
            >
              <Heading4
                marginBottom={MARGIN.M16}
                isResponsive={false}
                color={COLOR.BLACK}
              >
                Eligibility
              </Heading4>
              <Text marginBottom={MARGIN.M8} fontWeight={500}>
                You must:
              </Text>
              <List>
                <ListItem>Be 18 years or over</ListItem>
                <ListItem>Be a permanent Australian resident</ListItem>
              </List>
              <br />
              <Text marginBottom={MARGIN.M8} fontWeight={500}>
                Able to supply
              </Text>
              <List>
                <ListItem>
                  A copy of your driver’s licence, passport or proof of age card
                </ListItem>
                <ListItem>Details of your income, assets and debts</ListItem>
                <ListItem>Employer’s name and contact details</ListItem>
              </List>
            </div>
          }
        />

        <Section
          heading="Payment Options"
          id="payment-options"
          className="section--payment-options"
          css={`
            && {
              @media (min-width: ${BREAKPOINT.LG}) {
                padding-bottom: 72px;
              }
            }
          `}
        >
          <PaymentOptions
            data={[
              {
                id: 'latitude-app',
                title: 'Latitude App',
                titleHtml: 'Latitude App',
                content: (
                  <React.Fragment>
                    <PaymentOptionsColumn>
                      <PaymentOptionIcon>
                        <SvgInline name="payment-option-latitude-app" />
                      </PaymentOptionIcon>
                      <PaymentOptionTitle>Latitude App</PaymentOptionTitle>
                      <PaymentOptionDescription>
                        Make repayments in just a few taps with our mobile app.
                      </PaymentOptionDescription>
                      <PaymentOptionImages>
                        <Link
                          href="https://itunes.apple.com/app/apple-store/id1373059866?pt=118165058&ct=InfPayOpt0319&mt=8"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="d-inline"
                          data-trackid="payment-options-itunes-app-link"
                          trackEventData={{
                            label: ''
                          }}
                        >
                          <img
                            src={
                              require('../../../images/badge-app-store.svg')
                                .default
                            }
                            height="48px"
                            alt="app store"
                          />
                        </Link>
                        <Link
                          href="https://play.google.com/store/apps/details?id=com.latitudefinancial.latitudeapp&referrer=utm_source%3DInfPayOpt0319"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="d-inline"
                          data-trackid="payment-options-googleplay-app-link"
                          trackEventData={{
                            label: ''
                          }}
                        >
                          <img
                            src={
                              require('../../../images/badge-google-play.svg')
                                .default
                            }
                            height="48px"
                            alt="play store"
                          />
                        </Link>
                      </PaymentOptionImages>
                    </PaymentOptionsColumn>
                    <PaymentOptionsColumn>
                      <PaymentOptionList>
                        <PaymentOptionListItem>
                          <Strong>Get the app</Strong>
                          <Text fontSize="14px">
                            Simply download the Latitude App from the App Store
                            or Google Play
                          </Text>
                        </PaymentOptionListItem>
                        <PaymentOptionListItem>
                          <Strong>Create a Latitude ID</Strong>
                          <Text fontSize="14px">
                            Open the app and create your Latitude ID (this is
                            different to your Latitude Service Centre login).
                          </Text>
                        </PaymentOptionListItem>
                        <PaymentOptionListItem>
                          <Strong>Link your card</Strong>
                          <Text fontSize="14px">
                            Once your credit cards are linked, you’ll be able to
                            make payments via bank transfer with your BSB and
                            Account Number (Australia only).
                          </Text>
                        </PaymentOptionListItem>
                      </PaymentOptionList>
                    </PaymentOptionsColumn>
                    <PaymentOptionsColumn>
                      <PaymentOptionNote>
                        <Text fontSize="14px">
                          <Strong>Note:</Strong> Please be aware that bank
                          transfer payments can take three business days to
                          clear and you can only make one payment to your card
                          account every five days.
                        </Text>
                      </PaymentOptionNote>
                      <PaymentOptionCallout>
                        <Strong>No Fees</Strong>
                        <Text fontSize="14px">
                          We do not charge a fee for payments made via direct
                          bank transfer on the Latitude App.
                        </Text>
                      </PaymentOptionCallout>
                    </PaymentOptionsColumn>
                  </React.Fragment>
                )
              },
              {
                id: 'online-service-centre',
                title: 'Latitude Service Centre',
                titleHtml: 'Latitude Service Centre',
                content: (
                  <React.Fragment>
                    <PaymentOptionsColumn>
                      <PaymentOptionIcon>
                        <SvgInline name="payment-option-online" />
                      </PaymentOptionIcon>
                      <PaymentOptionTitle>
                        Latitude Service Centre
                      </PaymentOptionTitle>
                      <PaymentOptionDescription>
                        One of the quickest &amp; easiest way to make
                        repayments.
                      </PaymentOptionDescription>
                      <PaymentOptionButtons css="justify-content:center;">
                        <Link
                          target="_blank"
                          button="secondary"
                          href="https://servicecentre.latitudefinancial.com.au/login"
                          data-trackid="payment-options-service-center-login-link"
                          css="margin: 0 8px; flex: 1 1 50%;"
                          disableDefaultEventTracking
                        >
                          Login
                        </Link>
                        <Link
                          target="_blank"
                          button="tertiary"
                          href="https://servicecentre.latitudefinancial.com.au/register"
                          data-trackid="payment-options-service-center-register-link"
                          css="margin: 0 8px; flex: 1 1 50%;"
                          disableDefaultEventTracking
                        >
                          Register
                        </Link>
                      </PaymentOptionButtons>
                    </PaymentOptionsColumn>
                    <PaymentOptionsColumn>
                      <PaymentOptionHeading>
                        To make a payment
                      </PaymentOptionHeading>
                      <PaymentOptionList>
                        <PaymentOptionListItem>
                          <Strong>Login to the Latitude Service Centre</Strong>
                        </PaymentOptionListItem>
                        <PaymentOptionListItem>
                          <Strong>Click ‘Pay My Account’</Strong>
                          <Text fontSize="14px">
                            Then enter your bank details.
                          </Text>
                        </PaymentOptionListItem>
                      </PaymentOptionList>
                      <PaymentOptionHeading>Registering</PaymentOptionHeading>
                      <Text fontSize="14px">
                        Registering is easy and only takes a few minutes, just
                        make sure you have your card or 16-digit account number
                        on hand.
                      </Text>
                    </PaymentOptionsColumn>
                    <PaymentOptionsColumn>
                      <Text fontSize="14px">
                        <Strong>Note:</Strong> Please be aware that payments can
                        take 3 to 5 business days to clear.
                      </Text>
                      <PaymentOptionCallout>
                        <Strong>No Fees</Strong>
                        <Text fontSize="14px">
                          We do not charge a fee for payments made via the
                          Latitude Service Centre.
                        </Text>
                      </PaymentOptionCallout>
                    </PaymentOptionsColumn>
                  </React.Fragment>
                )
              },
              {
                id: 'bpay',
                title: 'BPAY',
                titleHtml:
                  'BPAY<sup style="font-size: 0.75em; top: -0.5em;">&reg;</sup>',
                content: (
                  <React.Fragment>
                    <PaymentOptionsColumn>
                      <PaymentOptionIcon>
                        <SvgInline name="payment-option-bpay" />
                      </PaymentOptionIcon>
                      <PaymentOptionTitle>
                        BPAY<sup>®</sup>
                      </PaymentOptionTitle>
                      <PaymentOptionDescription>
                        Available 24 hours a day, seven days a week.
                      </PaymentOptionDescription>
                    </PaymentOptionsColumn>
                    <PaymentOptionsColumn>
                      <PaymentOptionHeading>
                        To make a payment
                      </PaymentOptionHeading>
                      <PaymentOptionList>
                        <PaymentOptionListItem>
                          <Strong>Quote the Biller code</Strong>
                          <Text fontSize="14px">
                            Latitude 28° Global Platinum Mastercard Biller Code
                            (150615)
                          </Text>
                        </PaymentOptionListItem>
                        <PaymentOptionListItem>
                          <Strong>Your account number</Strong>
                        </PaymentOptionListItem>
                        <PaymentOptionListItem>
                          <Strong>The amount you wish to pay</Strong>
                        </PaymentOptionListItem>
                      </PaymentOptionList>
                    </PaymentOptionsColumn>
                    <PaymentOptionsColumn>
                      <Text fontSize="14px">
                        <Strong>Note:</Strong> If you’re paying by BPAY on your
                        due date, you must complete the transaction before your
                        bank’s cut off time, otherwise the transaction may be
                        processed on the following day. Some BPAY payments can
                        take up to three days to appear on your account.
                      </Text>
                      <PaymentOptionCallout>
                        <Strong>Fees</Strong>
                        <Text fontSize="14px">
                          A payment handling fee of $1.95 applies for all
                          payments made via BPAY<sup>&reg;</sup> to pay your
                          account.
                        </Text>
                      </PaymentOptionCallout>
                    </PaymentOptionsColumn>
                  </React.Fragment>
                )
              },
              {
                id: 'direct-debit',
                title: 'Direct Debit',
                titleHtml: 'Direct Debit',
                content: (
                  <>
                    <PaymentOptionsColumn>
                      <PaymentOptionIcon>
                        <SvgInline name="payment-option-direct-debit" />
                      </PaymentOptionIcon>
                      <PaymentOptionTitle>Direct Debit</PaymentOptionTitle>
                      <PaymentOptionDescription>
                        Easily make recurring repayments.
                      </PaymentOptionDescription>
                    </PaymentOptionsColumn>
                    <PaymentOptionsColumn longColumn>
                      <Text>
                        The easiest (and quickest) way to pay off your credit
                        card is with a <strong> monthly direct debit</strong>.
                      </Text>
                      <br />
                      <Text>
                        You can choose whether to pay a set amount, the monthly
                        minimum or the full closing balance, and on your due
                        date each month your payment will automatically happen!
                      </Text>
                      <br />
                      <Text>
                        <Link href="https://latitudefs.zendesk.com/hc/en-au/articles/6027991389329-Direct-Debit-Cards">
                          Click here to find out more about setting up a direct
                          debit.
                        </Link>
                      </Text>
                    </PaymentOptionsColumn>
                  </>
                )
              }
            ]}
            // tabContentMinHeight="385px"
            css={`
              && {
                .payment-options-tabs__content {
                  padding: 32px;
                }
              }
            `}
          />
        </Section>

        <Box
          css={`
            width: 100%;
            margin: 0 auto;
            padding-top: 40px;
            padding-bottom: 40px;
            @media (min-width: ${BREAKPOINT.LG}) {
              padding: 0 0 43px;
            }
          `}
          isResponsive
        >
          <Heading3
            css={`
              color: #000;
              font-family: ${FONT_FAMILY.TITLE};
              font-size: 20px;
              font-weight: 600;
              line-height: 24px;
              padding: 43px 0 0;
            `}
            className="text-center"
          >
            {cardsHeading}
          </Heading3>
          <div
            css={`
              display: flex;
              flex-direction: row;
              flex-wrap: wrap;
              gap: 30pt;
              justify-content: center;
              align-items: end;
              padding: 5pt;
            `}
          >
            {CARD_INFORMATION.map((item, i) => (
              <div
                key={i}
                css={`
                  padding: 5pt;
                  width: 120px;
                  text-align: center;
                `}
              >
                <img
                  css={`
                    width: ${item.isVertical ? '68px' : '108px'};
                    height: auto;
                    margin-bottom: 8px;
                  `}
                  src={item.imageSrc.default}
                  alt={item.title}
                />
                <div
                  css={`
                    text-align: center;
                    font-family: ${FONT_FAMILY.TITLE};
                    font-weight: 600;
                    padding-left: 2px;
                    line-height: 1.2;
                  `}
                >
                  {item.title}
                </div>
              </div>
            ))}
          </div>
        </Box>

        <Box.Section
          backgroundColor={COLOR.GREY5}
          paddingTop={MARGIN.M32}
          paddingBottom={MARGIN.M32}
        >
          <AnalyticsLocationProvider location="Keep exploring">
            <Heading4 align={ALIGN.CENTER} color={COLOR.BLACK}>
              Keep exploring
            </Heading4>
            <CardGroup>
              {KEEP_EXPLORING_INFO.map(item => (
                <CardGroupItem key={item.title}>
                  <ArticleTile isBranded {...item} />
                </CardGroupItem>
              ))}
            </CardGroup>
          </AnalyticsLocationProvider>
        </Box.Section>

        <Box.Section
          id="faq"
          css={`
            background-color: ${COLOR.WHITE};
            @media (min-width: ${BREAKPOINT.LG}) {
              width: 50%;
            }
          `}
        >
          <div data-contentful={state?.faqData?.[0]?.contentfulID}>
            <Heading4
              align={ALIGN.CENTER}
              color={COLOR.BLACK}
              paddingBottom={MARGIN.M64}
            >
              {state?.faqData?.[0]?.title || 'Frequently asked questions'}
            </Heading4>
            <AnalyticsLocationProvider
              location={
                state?.faqData?.[0]?.title || 'frequently asked questions'
              }
            >
              <Accordion
                css={`
                  .accordion__item-body {
                    padding: 16px 24px;
                    background-color: #f8f8f8 !important;
                  }
                `}
                titleBgColor="grey-light"
                items={state?.faqData?.[0]?.data || PageData.faq}
              />
            </AnalyticsLocationProvider>
            <Link
              button={BUTTON_STYLE.TERTIARY}
              css="width: 100%; margin: 64px auto; width: 320px"
              href={
                state?.faqData?.[0]?.cta?.url ||
                '/credit-cards/28-degrees/faqs/'
              }
              data-trackid="see-all-faq"
              trackEventData={{
                label: state?.faqData?.[0]?.cta?.text || 'See all FAQ'
              }}
            >
              {state?.faqData?.[0]?.cta?.text || 'See all FAQ'}
            </Link>
          </div>
        </Box.Section>

        <ImportantInformation
          id="important-Info"
          data={{
            content: {
              importantInformationSectionOne: [
                '# To take advantage of up to 55 days Interest Free on everyday credit card purchases, you need to pay the full closing balance on each statement of account by the applicable due date.',
                "<sup id='note-1'>1</sup> Latitude 28&deg; Global Platinum Mastercard cardholders will be eligible for a $10 Latitude Reward every statement period which is redeemable for an e-gift card from participating retailers when they spend $1,000 or more on eligible transactions using their credit card within the statement period. Any purchases made by an additional cardholder will contribute towards the primary cardholder's spend threshold. Eligible transactions are based on the date posted and do not include cash advances, cash equivalent transactions, card fees or charges, credits, refunds, reimbursements, Shopper's Protection premiums, interest and balance transfers into an account. The account must be open and not in default of the credit contract when the Latitude Rewards is issued. The Latitude Rewards will be issued by EonX Services Pty Ltd and will be available for redemption via the Latitude App or Latitude Service Centre within 30 days from the end of the statement period. The primary cardholder has up to 18 months to redeem from the date of issue. <a href='https://assets.latitudefinancial.com/legals/conditions-of-use/28degrees-au/cou.pdf' target='_blank'>View full terms and conditions.</a>'",
                '<sup id="note-2">2</sup> Purchase Protection Insurance is underwritten and issued by AIG Australia Limited ABN 93 004 727 753, AFSL 381 686, Level 19, 2 Park Street, Sydney NSW 2000 (“AIG”) under a group policy of insurance issued to Mastercard. In arranging the insurance Mastercard acts a group purchasing body under ASIC Corporations (Group Purchasing Bodies) Instrument 2018/751. Eligible persons who can access the complimentary Purchase Protection Insurance are the current holders of the relevant Latitude 28° Global Platinum Mastercard which entitles them to these purchase protection insurance benefits. It is important you read your Latitude 28° Global Platinum Mastercard Purchase Protection Insurance Terms and Conditions. Eligible persons can claim as third-party beneficiaries by virtue of the operation of s48 of the Insurance Contracts Act 1984. Conditions and Exclusions apply to this insurance coverage as set out in the group policy and any insurance document provided to the eligible persons and which both may be amended from time to time. Neither Latitude Finance Australia nor Mastercard guarantees this insurance and do not hold an Australian Financial Services Licence. <a targe="_blank" href="https://assets.latitudefinancial.com/legals/28degreescard/purchase-protection-insurance-termsconds.pdf">View full terms and conditions</a>.',
                '<sup id="note-3">3</sup> E-Commerce Purchase Protection Insurance is underwritten and issued by AIG Australia Limited ABN 93 004 727 753, AFSL 381 686, Level 19, 2 Park Street, Sydney NSW 2000 (“AIG”) under a group policy of insurance issued to Mastercard. In arranging the insurance Mastercard acts a group purchasing body under ASIC Corporations (Group Purchasing Bodies) Instrument 2018/751. Eligible persons who can access the complimentary E-Commerce Purchase Protection Insurance are the current holder of the relevant Latitude 28° Global Platinum Mastercard which entitles them to these e-commerce purchase protection insurance benefits. It is important you read your Latitude 28° Global Platinum Mastercard E-Commerce Purchase Protection Insurance Terms and Conditions. Eligible persons can claim as third-party beneficiaries by virtue of the operation of s48 of the Insurance Contracts Act 1984. Conditions and Exclusions apply to this insurance coverage as set out in the group policy and any insurance document provided to the eligible persons and which both may be amended from time to time. Neither Latitude Finance Australia nor Mastercard guarantees this insurance and do not hold an Australian Financial Services Licence. <a targe="_blank" href="https://assets.latitudefinancial.com/legals/28degreescard/ecommerce-protection-insurance-termscond.pdf">View full terms and conditions</a>.'
              ],
              importantInformationSectionTwo: [
                '<sup id="note-4">4</sup> The Flight Delay Pass gives free access to select airport lounges, alternatively cardholders can use their Flight Delay Pass to offset a dollar amount off the total bill at select dining options. This will vary based on location and affiliated LoungeKey Partners at the time. Please note that not all airports offer Flight Delay Pass and the selected airports may be restricted to certain terminals. Access to and use of the Flight Delay Pass is subject to the Terms of Use which are subject to change. Further details of the list of lounges that cardholders are eligible to visit and the specific conditions applicable to each lounge access (Conditions of Use) may also be accessed in the Terms of Use. Participating lounges and their Conditions of Use are subject to change.',
                "<sup id='note-5'>5</sup>&nbsp;$200 Latitude Reward is available to new and approved applicants who apply for a Latitude 28° Global Platinum Mastercard and is limited to one offer per applicant. To qualify, you must be approved and fulfil on the terms of the offer by spending $2,000 or more on eligible transactions each statement period for 3 months from the date of your approval. Any purchases made by an additional cardholder will contribute towards the primary cardholder's spend threshold. Eligible transactions are based on the date posted and do not include cash advances, cash equivalent transactions, card fees or charges, credits, refunds, reimbursements, Shopper's Protection premiums, interest and balance transfers into an account. The account must be open and not in default or the credit contract when the redeemable amount is issued. The $200 Latitude Reward will be issued by EonX Services Pty Ltd and will be available for redemption via the Latitude App or Latitude Service Centre within 30 days of meeting the spend criteria. The primary cardholder has up to 18 months to redeem from the date of issue. Offer ends 31 December 2024. Latitude may vary, extend or withdraw this offer at any time without notice.",
                'Mastercard and the circles design are registered trademarks of Mastercard International Incorporated.',
                'Apple, the Apple logo, Apple Pay, Apple Watch, Face ID, iPad, iPhone, Safari, and Touch ID are trademarks of Apple Inc., and App Store is a service mark of Apple Inc., registered in the U.S. and other countries.',
                'Samsung and Samsung Pay are trademarks or registered trademarks of Samsung Electronics Co., Ltd.',
                'Garmin, the Garmin logo, and the Garmin delta are trademarks of Garmin Ltd. or its subsidiaries and are registered in one or more countries, including the U.S. Garmin Pay is a trademark of Garmin Ltd. or its subsidiaries.',
                'Google Pay, Android, the Google Pay Logo, Google Play and the Google Play logo are trademarks of Google LLC.',
                '&reg; Registered to BPAY Pty Ltd ABN 69 079 137 518'
              ]
            }
          }}
          sectionOneColummClasses="col-10 offset-1 col-lg-5 offset-lg-1"
          sectionTwoColummClasses="col-10 offset-1 col-lg-5 offset-lg-0"
          {...state?.importantInfoData?.[0]}
        />
      </main>
    </Layout>
  );
};

export default DegreesCardPage;
